/*
COLORNAME
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 21, 2022
UPDATE DATE: May 21, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ColorName | Find your color name!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

%anim{
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  @extend %anim;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  @extend %anim;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.react-select{
  .react-select__control{
    border: 1px solid #CCC;
    border-radius: 5px;
    min-height: 40px;
    box-shadow: none;
    @extend %anim;

    &.react-select__control--is-focused,
    &:hover{
      border: 1px solid #999;

      .react-select__indicators{
        background: #999;
      }
    }

    .react-select__value-container{
      padding: 0 15px;

      .react-select__single-value{
        margin: 2px 0 0 0;
        font-size: 14px;
        line-height: 18px;
      }

      .react-select__input-container{
        margin: 0;
        padding: 0;

        input{
          font-size: 14px !important;
        }
      }
    }

    .react-select__indicators{
      cursor: pointer;
      background: #CCC;
      border-radius: 0 4px 4px 0;
      @extend %anim;

      .react-select__indicator-separator{
        display: none;
        width: 0;
      }

      .react-select__indicator{
        color: #FFF;
        padding: 10px;

        svg{
          width: 18px;
          height: 18px;
          @extend %anim;
        }
      }
    }

    &.react-select__control--menu-is-open{
      border-radius: 5px 5px 0 0;

      .react-select__indicators{
        border-radius: 0 4px 0 0;

        svg{
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.react-select__menu-portal{
  z-index: 4 !important;

  .react-select__menu{
    margin: 0;
    border-radius: 0 0 5px 5px;
    border: 1px solid #999;
    border-top: none;
    box-shadow: 0 20px 20px -10px rgba(0,0,0,.1);
    overflow: hidden;

    .react-select__menu-list{
      padding: 0;

      .react-select__option{
        cursor: pointer;
        color: #666;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 18px;
        @extend %anim;

        &.react-select__option--is-focused{
          background: #EEE;
        }

        &.react-select__option--is-selected{
          background: #666;
          color: #FFF;
        }
      }
    }
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > ul{
    margin: -5px;
    overflow: auto;

    > li{
      position: relative;
      padding: 5px;
      float: left;
      box-sizing: border-box;

      &:nth-child(1){
        width: 55%;

        > a{
          float: left;
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;

          > svg{
            display: block;
            width: 164px;
            height: 40px;
          }
        }

        .today{
          margin-left: 174px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          // border: 1px solid #CCC;
          // background: #FFF;
          background: #EEE;
          padding: 0 10px;
          border-radius: 5px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @extend %anim;

          &:hover{
            background: #DDD;
            color: #888;

            > strong{
              color: #555;
            }
          }

          > strong{
            color: #666;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4){
        width: 15%;
      }
      
      > label{
        background: #fff;
        font-size: 11px;
        left: 16px;
        line-height: 11px;
        padding: 0 5px;
        position: absolute;
        top: 1px;
        z-index: 1;
      }

      > button{
        background: #666;
        color: #FFF;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        font-weight: bold;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover{
          background: #333;
        }

        span{
          display: block;
          font-size: 9px;
          font-weight: normal;
          margin: 0 0 1px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > svg{
          display: inline-block;
          vertical-align: middle;
          margin: -3px 5px 0 0;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.pad-top{
  height: 70px;
}

footer{
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }
}

ul.calendar{
  clear: both;
  padding: 10px;

  > li{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;

    &.year{
      text-align: center;
      color: #666;
      font-size: 140px;
      line-height: 144px;
      font-weight: 100;
      padding-bottom: 0;
    }

    > div{
      > div{
        > .head{
          font-size: 32px;
          line-height: 36px;
          color: #666;
          padding-bottom: 10px;

          &.sticky{
            position: fixed;
            top: 70px;
            left: 0;
            right: 0;
            padding: 0 20px 10px 20px;
            background: rgba(255, 255, 255, .95);
            z-index: 3;
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);
          }

          &.was-sticky{
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 9px;
            padding: 0 10px 10px 10px;
            background: rgba(255, 255, 255, .95);
            z-index: 2;
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            box-shadow: none;
          }
        }
      }
  
      > ul.day{
        margin: .5px -.5px -.5px -.5px;
        
        > li{
          display: inline-block;
          vertical-align: top;
          width: 14.28%;
          padding: .5px;
          box-sizing: border-box;
          @extend %anim;

          &.today{
            > div{
              > div{
                box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);

                &:hover{
                  box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                }
              }
            }
          }
  
          > div{
            position: relative;
            height: 0;
            padding: 50% 0;
            background: #EEE;
  
            > div{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              // background: #AAA;
              color: #FFF;
              padding: 20px;
              font-size: 18px;
              line-height: 22px;
              cursor: pointer;
              z-index: 1;
              @extend %anim;
  
              &:hover{
                transform: scale(1.1, 1.1);
                box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0), 0 20px 20px -10px rgba(0,0,0,.25);
                z-index: 2;
              }
  
              > ul.date-info{
                pointer-events: none;

                > li{
                  &:nth-child(1){
                    font-weight: 100;
                    font-size: 48px;
                    line-height: 48px;
                  }

                  &:nth-child(2){
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;
                  }

                  &:nth-child(3){
                    padding-top: 2px;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: bold;
                  }
                }
              }

              > ul.color-info{
                pointer-events: none;
                display: none;
                background: rgba(0, 0, 0, .25);
                // display: block;
                margin: 20px 0;
                padding: 15px;
                // padding: 20px 0 0 0;
                font-size: 10px;
                line-height: 14px;
                overflow: auto;

                li{
                  float: left;
                  padding: 5px;
                  font-weight: bold;
                  text-align: left;
                  white-space: nowrap;
                  box-sizing: border-box;

                  span{
                    display: block;
                    font-weight: normal;
                  }

                  .colorname{
                    display: inline-block;
                    vertical-align: middle;
                    margin: -1px 3px 0 0;
                    width: 9px;
                    height: 9px;
                    border: 1px solid rgba(255, 255, 255, .5);
                  }

                  .chart{
                    height: 15px;
                    background: rgba(255, 255, 255, .5);
                    padding: 1px;
                    margin: 5px 0 0 0;
                    box-sizing: border-box;
                    
                    > span{
                      position: relative;
                      display: block;
                      height: 13px;
                      transition: all 200ms ease-in-out;
                      -moz-transition: all 200ms ease-in-out;
                      -webkit-transition: all 200ms ease-in-out;

                      .pointer{
                        position: absolute;
                        top: -4px;
                        bottom: -4px;
                        width: 1px;
                        background: #FFF;
                        transform: translate(-.5px, 0);
                        transition: all 200ms ease-in-out;
                        -moz-transition: all 200ms ease-in-out;
                        -webkit-transition: all 200ms ease-in-out;
                      }

                      &.huepicker{
                        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff0000+0,ffff00+17,00ff00+33,00ffff+50,0000ff+67,ff00ff+83,ff0000+100 */
                        background: rgb(255,0,0); /* Old browsers */
                        background: -moz-linear-gradient(left,  rgba(255,0,0,1) 0%, rgba(255,255,0,1) 17%, rgba(0,255,0,1) 33%, rgba(0,255,255,1) 50%, rgba(0,0,255,1) 67%, rgba(255,0,255,1) 83%, rgba(255,0,0,1) 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(left,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to right,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ff0000',GradientType=1 ); /* IE6-9 */
                      }

                      &.darkpicker{
                        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
                        background: rgb(0,0,0); /* Old browsers */
                        background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
                      }
                    }

                    &.multi{
                      > span{
                        display: inline-block;
                      }
                    }
                  }

                  &.lay2{
                    width: 50%;
                  }

                  &.lay3{
                    width: 33.33%;
                  }

                  &.lay4{
                    width: 25%;
                  }

                  &.layfull{
                    width: 100%;
                  }

                  &.clear{
                    clear: both;
                  }
                }
              }
              
              > div{
                pointer-events: none;
                position: absolute;
                bottom: 20px;

                > ul{
                  margin: -1px 0;

                  > li{
                    padding: 1px 0;
                    font-size: 12px;
                    line-height: 16px;

                    &:nth-child(1),
                    &:nth-child(2){
                      display: none;
                    }
                  }
                }

                &.quarter{
                  top: 20px;
                  bottom: auto;
                  right: 20px;
                  font-size: 12px;
                  line-height: 16px;
                }

                &.value{
                  left: 20px;
                }

                &.percent{
                  right: 20px;
                  font-weight: bold;
                  text-align: right;
                }
              }
            }
          }

          &.big{
            width: 100%;

            > div{
              padding: 20px;
              height: auto;
              margin: 20px 0;

              > div{
                position: static;
                margin: -20px;
                padding-bottom: 84px;

                &:hover{
                  transform: scale(1, 1);
                }

                > ul.date-info{
                  > li{
                    &:nth-child(1){
                      font-size: 82px;
                      line-height: 82px;
                    }
  
                    &:nth-child(2){
                      font-size: 16px;
                      line-height: 20px;
                    }
  
                    &:nth-child(3){
                      font-size: 20px;
                      line-height: 24px;
                    }
                  }
                }
                
                > ul.color-info{
                  display: block;
                }

                > div{
                  &.quarter{
                    font-size: 18px;
                    line-height: 22px;
                  }

                  > ul{
                    > li{
                      font-size: 16px;
                      line-height: 20px;

                      &:nth-child(1),
                      &:nth-child(2){
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
  
        &.inline{
          > li{
            &.today{
              > div{
                > div{
                  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);
  
                  &:hover{
                    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                  }
                }
              }
            }

            > div{
              > div{
                &:hover{
                  box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0), 0 10px 10px -5px rgba(0,0,0,.25);
                }

                > span{
                  top: -5px;
                  right: -5px;
                  border: 5px solid transparent;
                  border-bottom: 5px solid #FFF;
                }

                > div{
                  display: none;
                }
                
                > ul.date-info{
                  position: absolute;
                  top: 50%;
                  transform: translate(0, -50%);
                  left: 0;
                  right: 0;

                  > li{
                    text-align: center;

                    &:nth-child(1){
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 10px;
                      line-height: 14px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }
              }
            }

            &.big{
              width: 100% !important;
              
              &.today{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);
    
                    &:hover{
                      box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                    }
                  }
                }
              }

              > div{
                > div{
                  &:hover{
                    transform: scale(1, 1);
                  }

                  > span{
                    top: -10px;
                    right: -10px;
                    border: 10px solid transparent;
                    border-bottom: 10px solid #FFF;
                  }

                  > div{
                    display: block;
                  }

                  > ul.date-info{
                    position: static;
                    transform: translate(0, 0);

                    > li{
                      text-align: left;

                      &:nth-child(1){
                        font-size: 82px;
                        line-height: 82px;
                      }
    
                      &:nth-child(2){
                        font-size: 16px;
                        line-height: 20px;
                      }
    
                      &:nth-child(3){
                        display: block;
                        font-size: 20px;
                        line-height: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 49%;

          > a{
            > svg{
              width: 144px;
            }
          }
      
          .today{
            margin-left: 154px;
            font-size: 13px;

            > span{
              display: none;
            }
          }
        }

        &:nth-child(2){
          width: 18%;
        }

        &:nth-child(3){
          width: 18%;
        }

        &:nth-child(4){
          width: 15%;
        }
      }
    }
  }

  ul.calendar{
    > li{
      > div{
        > ul.day{
          &.inline{
            > li{
              > div{
                > div{
                  > ul.date-info{
                    > li{
                      &:nth-child(1){
                        font-size: 10px;
                        line-height: 12px;
                      }
                      
                      &:nth-child(2){
                        font-size: 8px;
                        line-height: 10px;
                      }
                    }
                  }
                }
              }
            }
          }

          > li{
            > div{
              > div{
                padding: 15px;

                > ul.date-info{
                  > li{
                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > div{
                  &.quarter{
                    right: 15px;
                    top: 15px;
                  }

                  &.value,
                  &.percent{
                    display: none;
                  }
                }
              }
            }

            &.big{
              > div{
                > div{
                  padding: 20px;

                  > ul.date-info{
                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    &.quarter{
                      right: 20px;
                      top: 20px;
                      font-size: 18px;
                      line-height: 22px;
                    }
  
                    &.value,
                    &.percent{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 33.33%;
        }
      }
    }
  }

  .pad-top{
    height: 120px;
  }

  ul.calendar{
    > li{
      &.year{
        font-size: 120px;
        line-height: 124px;
      }

      > div{
        > div{
          > div{
            &:first-child{
              padding-bottom: 0 !important;
            }
          }

          > .head{
            &.sticky{
              // position: static;
              // padding: 0;
              top: 120px;
            }
          }
        }

        > ul.day{
          &.inline{
            > li{
              > div{
                padding: 200% 0;

                > div{
                  padding: 0;

                  > ul{
                    display: none;
                  }
                }
              }
            }
          }

          > li{
            > div{
              > div{
                padding: 10px;

                > ul.date-info{
                  > li{
                    &:nth-child(1){
                      font-size: 32px;
                      line-height: 32px;
                      // font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 12px;
                      line-height: 16px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > div{
                  &.quarter{
                    right: 10px;
                    top: 10px;
                    font-size: 11px;
                    line-height: 15px;
                  }

                  &.value,
                  &.percent{
                    display: none;
                  }
                }
              }
            }

            &.big{
              > div{
                padding: 150px 20px;

                > div{
                  padding: 20px;

                  > ul.date-info{
                    display: block;

                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    &.quarter{
                      right: 20px;
                      top: 20px;
                      font-size: 18px;
                      line-height: 22px;
                    }
  
                    &.value,
                    &.percent{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 33.33%;
        }
      }
    }
  }

  .pad-top{
    height: 120px;
  }

  ul.calendar{
    > li{
      &.year{
        font-size: 120px;
        line-height: 124px;
      }

      > div{
        > div{
          > div{
            &:first-child{
              padding-bottom: 0 !important;
            }
          }

          > .head{
            &.sticky{
              position: static;
              padding: 0;
            }
          }
        }

        > ul.day{
          &.inline{
            > li{
              > div{
                padding: 200% 0;

                > div{
                  padding: 0;

                  > ul{
                    display: none;
                  }
                }
              }
            }
          }

          > li{
            &.today{
              > div{
                > div{
                  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);
  
                  &:hover{
                    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                  }
                }
              }
            }

            > div{
              > div{
                padding: 10px;

                > ul.date-info{
                  > li{
                    &:nth-child(1){
                      font-size: 32px;
                      line-height: 32px;
                      // font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 12px;
                      line-height: 16px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > div{
                  &.quarter{
                    right: 10px;
                    top: 10px;
                    font-size: 11px;
                    line-height: 15px;
                  }

                  &.value,
                  &.percent{
                    display: none;
                  }
                }
              }
            }

            &.big{
              &.today{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);
    
                    &:hover{
                      box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                    }
                  }
                }
              }

              > div{
                padding: 150px 20px;

                > div{
                  padding: 20px;

                  > ul.date-info{
                    display: block;

                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    &.quarter{
                      right: 20px;
                      top: 20px;
                      font-size: 18px;
                      line-height: 22px;
                    }
  
                    &.value,
                    &.percent{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  footer{
    padding: 20px 20px 40px 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;

          > a{
            > svg{
              width: 113px;
            }
          }

          .today{
            font-size: 12px;
            margin: 0 0 0 123px;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 33.33%;
        }
      }
    }
  }

  .pad-top{
    height: 120px;
  }

  ul.calendar{
    > li{
      &.year{
        font-size: 120px;
        line-height: 124px;
      }

      > div{
        > div{
          > div{
            &:first-child{
              padding-bottom: 0 !important;
            }
          }

          > .head{
            &.sticky{
              position: static;
              padding: 0;
            }
          }
        }

        > ul.day{
          &.inline{
            > li{
              &.today{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);
    
                    &:hover{
                      box-shadow: inset 0 0 0 3px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                    }
                  }
                }
              }

              > div{
                padding: 200% 0;

                > div{
                  padding: 0;

                  > ul{
                    display: none;
                  }
                }
              }
            }
          }

          > li{
            &.today{
              > div{
                > div{
                  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);
  
                  &:hover{
                    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                  }
                }
              }
            }

            > div{
              > div{
                padding: 5px 7px;

                > ul.date-info{
                  > li{
                    &:nth-child(1){
                      font-size: 16px;
                      line-height: 20px;
                      font-weight: normal;
                    }

                    &:nth-child(2){
                      font-size: 10px;
                      line-height: 10px;
                    }

                    &:nth-child(3){
                      display: none;
                    }
                  }
                }

                > ul.color-info{
                  .lay2,
                  .lay3,
                  .lay4{
                    width: 100% !important;
                  }
                }

                > div{
                  display: none;
                }
              }
            }

            &.big{
              &.today{
                > div{
                  > div{
                    box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 0 0 0 rgba(0,0,0,.25);
    
                    &:hover{
                      box-shadow: inset 0 0 0 10px rgba(255, 255, 255, .5), 0 20px 20px -10px rgba(0,0,0,.25);
                    }
                  }
                }
              }

              > div{
                // padding: 50% 0;
                padding: 20px;

                > div{
                  padding: 20px;
                  padding-bottom: 84px;

                  > ul.date-info{
                    display: block;

                    > li{
                      &:nth-child(3){
                        display: block;
                      }
                    }
                  }

                  > div{
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  footer{
    padding: 0 20px 20px 20px;
  }
}

// @media screen and (min-width: 1025px) and (max-width: 1600px){}

// @media screen and (min-width: 768px) and (max-width: 1024px){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

// @media screen and (max-width: 767px){}

// @media screen and (max-width: 767px) and (orientation: landscape){}

// @media screen and (max-width: 767px) and (orientation: portrait){}